// main imports
import * as React from "react";

// plugin imports
import { Trans, useTranslation } from "react-i18next";
import { AnimatePresence, motion } from "framer-motion";

// style imports
import "./bookingCode.scss";
import "../../pages/reserva/reserva.css";

// svg imports

// components imports
import { checkUnlockingCode } from "../../utils/coverAPI.js";

// page
export default function BookingCode({
	bookingForm,
	validRestaurant = "restaurante-begin-cortes",
	afterForm,
	codeLock = false,
	validCode,
	setValidCode,
	formTitle = undefined,
	codeWelcomeText = "",
}) {
	const { t } = useTranslation("booking");

	const [codeError, setCodeError] = React.useState(false);
	const [userCode, setUserCode] = React.useState("");

	const handleCodeValidation = () => {
		checkUnlockingCode(
			validRestaurant, // Indiferente si los códigos son para todos los restaurantes
			userCode,
			codeLock,
			setValidCode,
			setCodeError
		);
	};

	const fadeItem = {
		initial: "hidden",
		animate: "show",
		exit: "hidden",
		variants: {
			show: {
				opacity: 1,
				transition: {
					staggerChildren: 0.2,
				},
			},
			hidden: {
				opacity: 0,
			},
		},
	};

	return (
		<>
			<section id="bookingContainer" className="bookingCode">
				{formTitle && <p className="h2">{formTitle}</p>}
				<AnimatePresence mode="wait">
					{codeLock && !validCode ? (
						<motion.div
							key="codeValidator"
							className="codeValidator"
							{...fadeItem}
						>
							<div>
								<label
									htmlFor="code"
									aria-label={t("Introduce código", { ns: "booking" })}
								>
									<p className="h2 lowercase">{codeWelcomeText}</p>
								</label>
								<input
									type="text"
									placeholder={t("Código", { ns: "booking" })}
									id="code"
									name="code"
									title=""
									required
									value={userCode || ""}
									maxLength={12}
									onChange={(e) => {
										setCodeError(undefined);
										setUserCode(e.target.value);
									}}
								/>
							</div>
							{codeError && (
								<motion.div
									className="formWarning"
									initial="hidden"
									animate="animate"
									exit="hidden"
									variants={{
										hidden: {
											marginTop: 0,
											opacity: 0,
											height: 0,
										},
										animate: {
											marginTop: "0.5rem",
											opacity: 0.6,
											height: "auto",
										},
									}}
								>
									<Trans ns="booking">Código incorrecto</Trans>
								</motion.div>
							)}
							<button
								className="roundButton small"
								onClick={handleCodeValidation}
							>
								<Trans ns="booking">Validar</Trans>
							</button>
						</motion.div>
					) : (
						<motion.div key="bookingForm" {...fadeItem}>
							{codeLock && (
								<div className="codeSuccess">
									<h2 className="lowercase">
										<Trans ns="booking">Código válido</Trans>
									</h2>
									<p>
										<Trans ns="booking">
											Ya puedes realizar tu reserva con tu código
										</Trans>{" "}
										{userCode}{" "}
										<Trans ns="booking">
											en cualquiera de nuestros restaurantes.
										</Trans>
									</p>
									<p>
										{
											codeLock.find(
												(code) =>
													code.codeCoverDescription === validCode.description
											).codeDisplayDescription
										}
									</p>
								</div>
							)}

							{bookingForm}
						</motion.div>
					)}
				</AnimatePresence>

				{/* <p className="codeDisclaimer">
					<Trans ns="booking">
						Cada código es de uso único y exclusivo, si detectamos dos o más
						reservas realizadas con el mismo código, procederemos a la anulación
						de todas ellas.
					</Trans>
				</p> */}

				{afterForm}
			</section>
		</>
	);
}
