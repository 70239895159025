import "../../styles/section.css";
import "../reserva/reserva.css";

import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { graphql } from "gatsby";
// import { Link } from "gatsby-plugin-react-i18next";
// import IframeResizer from "iframe-resizer-react";

import Layout from "../../components/layout/Layout";
import Seo from "../../components/seo";
import LocalesGrid from "../../components/localesGrid/localesGrid";
// import CodeProtection from "../../components/reserva/CodeProtection"

import FlorituraHoja from "../../svg/florituras/florituraHoja.svg";
import Subrayado5 from "../../svg/subrayados/subrayado05.svg";
import Booking from "../../components/reserva/booking";
import BookingCode from "../../components/reserva/bookingCode";

export default function IndexPage({ data }) {
	const { t } = useTranslation();

	const [validCode, setValidCode] = React.useState(undefined);

	// An array of Covermanager valid codes
	const codeLock = [
		{
			codeCoverDescription: "Vale por un Begincumple valorado en 50 euros",
			codeDisplayDescription: t(
				"La reserva se hace con tu código e incluye el vale de 50€ de forma automática."
			),
		},
	];

	return (
		<Layout className="invertColors">
			<Seo title={t("Reserva")} />

			<div id="reserva" className="section invertColors reservaCumpleaños">
				<div className="tituloReserva">
					<h1 className="lowercase">
						<Trans i18nKey="titleReservaCumpleaños">
							¡Feliz <i>cumpleaños</i>!
						</Trans>
					</h1>
				</div>

				<BookingCode
					codeLock={codeLock}
					validCode={validCode}
					setValidCode={setValidCode}
					codeWelcomeText={t(
						"Introduce el código para desbloquear tu descuento y acceder al formulario exclusivo de reservas"
					)}
					bookingForm={
						<Booking
							restaurantsData={data.restaurants}
							promotionalCode={validCode}
						/>
					}
				/>

				{/* <div id="coverManager" className="abouttext reservatext reservaForm">
					<ReservaPlaceholder />
					<>
						<script
							type="text/javascript"
							src="https://www.covermanager.com/js/iframeResizer.min.js"
						></script>
						{language === "en" ? (
							<IframeResizer
								id="restaurante-voltereta-general"
								title="Reservas"
								src="https://www.covermanager.com/reserve/module_pre_filter/begin/english"
								frameBorder="0"
								height="300"
								width="100%"
								heightCalculationMethod="max"
								scrolling="true"
							/>
						) : (
							<IframeResizer
								id="restaurante-voltereta-general"
								title="Reservas"
								src="https://www.covermanager.com/reserve/module_pre_filter/begin/spanish"
								frameBorder="0"
								height="300"
								width="100%"
								heightCalculationMethod="max"
								scrolling="true"
							/>
						)}
					</>

					<div className="pieReserva">
						<p>
							<Trans>
								Las reservas están disponibles hasta un máximo de 30 días de
								antelación.
							</Trans>
						</p>
						<p>
							<Trans>
								El número máximo de personas varía según el local. Si necesitas
								espacio extra contáctanos <Link to="/contacto">aquí</Link> e
								intentaremos hacer lo posible para ayudarte.
							</Trans>
						</p>
					</div>
				</div> */}

				<div className="tituloReserva localesGridTitulo">
					<h3>
						<Trans>
							¿Quieres descubrir más sobre cada uno de los espacios?
						</Trans>
					</h3>
				</div>
				<LocalesGrid restaurants={data.restaurants} />

				<FlorituraHoja className="footerFloritura" />

				<div className="prefooterNote">
					<h2>
						<Trans>
							También tenemos la opción de{" "}
							<a href="https://linktr.ee/begintakeaway">
								recoger en local
								<Subrayado5 />
							</a>{" "}
							o de{" "}
							<a href="https://linktr.ee/beginrestaurante">
								envío a domicilio
								<Subrayado5 />
							</a>{" "}
							,
						</Trans>
					</h2>
					<h2>
						<Trans>¡Tú decides cómo vivir la experiencia!</Trans>
					</h2>
				</div>
			</div>
		</Layout>
	);
}

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}

		restaurants: allMarkdownRemark(
			filter: { fields: { language: { eq: $language } } }
			sort: { order: ASC, fields: fileAbsolutePath }
		) {
			nodes {
				fields {
					language
				}
				frontmatter {
					id
					coverManagerId
					simpleName
					address1
					addressCity
					addressLink
					addressPostal
					deliveryLinks {
						name
						url
					}
					hours {
						brunch
						dinner
						lunch
					}
					maxPeople
					menuBrunch
					menuDia
					menuNavidad
					menuDegustacionNavidad
					menuGrupo
					menuNoche
					takeAwayLink
					subtitle
					title
					images {
						childImageSharp {
							gatsbyImageData
						}
					}
				}
			}
		}
	}
`;
